/*
 * Themes for each client.
 */

@import 'theme-variables';
@import 'global-variables';

// Type

h1,
h2,
h3,
h4 {
    text-transform: uppercase;
    font-family: $headings-font-family !important;
}

h5,
h6 {
    font-weight: 400;
}

.fw-bold {
    font-weight: 600 !important;
}

.gradient-text {
    background: linear-gradient(to right, $secondary-gradient 0%, $primary-gradient 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// Logo Loader

.logo-loader {
}

// Container

.container-fluid {
    width: 90% !important;
    @media (max-width: $tablet) {
        width: 100% !important;
    }
}

// Navbar
.o-navbar {
    .menu-container {
        @media (max-width: $tablet) {
            padding-left: unset;
        }
    }
    .navbar-logo {
        img {
            width: 8rem !important;
            height: auto;
        }
    }
    .nav-link {
        font-family: $fontFamily !important;
    }
    .btn {
        text-transform: uppercase;
        padding: 0.5rem 2rem !important;
        font-size: 0.875rem !important;
    }
    .o-waitlist-form {
        .icon {
            top: 0.1rem !important;
        }
        .form-wrapper {
            display: flex !important;
            input {
                background: transparent;
                border-style: solid;
                border-color: antiquewhite;
                padding: 0.6rem 1rem 0.6rem 3rem !important;
                font-size: 0.875rem !important;
            }
        }
    }
}

.a-loader {
    .paginator div {
        background: map-get($theme-colors, 'primary') !important;
    }
}

.modal-backdrop {
    z-index: 1050 !important;
}

// Footer

.o-footer {
    .footer-logo {
        img {
            width: 8rem !important;
            height: auto;
        }
    }
    p {
        font-size: 1rem;
    }
    .scalio-content {
        display: flex;
        align-items: center;
        @media (max-width: $tablet) {
            flex-direction: column;
        }
    }
    .copyright {
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: $tablet) {
                flex-direction: column;
                p {
                    margin-bottom: 2rem !important;
                }
            }
        }
    }
}

//Buttons

.btn {
    font-family: $headings-font-family !important;
    font-size: 1rem;
    border: 2px solid white !important;
    background: map-get($theme-colors, 'primary');
    @media (max-width: $mobile) {
        padding: 1rem !important;
        width: 100%;
    }
    &:hover,
    &:focus,
    &:active {
        transition: all 0.5s ease;
        background-color: map-get($theme-colors, 'primary') !important;
        color: $white !important;
    }
}

// Audio Component

.a-audio {
    .song-image {
        @media (max-width: $mobile) {
            svg {
                width: 3rem;
                height: auto;
            }
        }
        @media (max-width: 374px) {
            display: none;
        }
    }
    .song-info {
        @media (max-width: $mobile) {
            p,
            small {
                font-size: 16px;
            }
        }
    }
    .main-controls {
        @media (max-width: $mobile) {
            display: flex;
            svg {
                width: 0.875rem;
                height: auto;
            }
        }
    }
    .audio-container {
        @media (max-width: $mobile) {
            padding: 10px 0 !important;
        }
    }
}

// Section Title

.half-title {
    font-family: $headings-font-family;
    color: white;
    font-size: calc(1.25rem + 0.3vw);
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
}

.bg-gradient {
    background: linear-gradient(to right, $secondary-gradient 0%, $primary-gradient 100%) !important;
    border-radius: 5rem;
}
// Home Page

.m-section-title {
    h2 {
        font-size: $h1-font-size;
        @media (max-width: $tablet) {
            font-size: calc(1.525rem + 3.3vw);
        }
    }
}

.modal-content {
    background-color: transparent !important;
    .modal-dialog {
        margin: 0;
        background-color: #071935;
        @media (min-width: $tablet) {
            padding: 2rem;
            border-radius: 1.75rem;
        }
    }
    .member-wrapper,
    .supermap-wrapper {
        @media (min-width: $tablet) {
            background-image: url('/assets/img/team-modal-frame.webp');
        }
        background-repeat: round;
        background-size: cover;
        .image {
            display: flex;
            align-items: center;
        }
    }

    .member-image {
        min-height: 25rem !important;
        width: 18rem !important;
        @media (max-width: $tablet) {
            width: 100% !important;
        }

        border-radius: 1.75rem;
        border: 0.25rem solid #8d58b9;
        margin: 0 auto;
    }
    .supermap-image {
        @media (max-width: $tablet) {
            width: 100% !important;
        }
        margin: 0 auto;
    }
    h4 {
        text-transform: capitalize !important;
    }
    .overflow-content {
        height: 42vh;
        @media (max-width: $tablet) {
            height: 100%;
        }
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5rem;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

.m-modal {
    .close-background {
        @media (min-width: $tablet) {
            right: 0 !important;
            top: 0 !important;
        }
    }
}

.o-book-modal-section {
    .overlay {
        background-image: linear-gradient(to bottom, transparent, $black);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }
    .background-img-section {
        border-radius: 0.5rem !important;
        overflow: hidden;
        position: relative;
    }
    .featured-ellipsis-h {
        -webkit-line-clamp: 4 !important;
        height: 7rem !important;
        font-size: 1rem !important;
    }
    .a-image .background-img-section {
        height: 100% !important;
    }

    .card-play {
        top: 8rem;
        img {
            width: auto !important;
        }
    }
}

.book-modal-wrapper {
    .modal-dialog {
        background: linear-gradient(#121721 0 0) padding-box,
            linear-gradient(to right, $secondary-gradient 0%, $primary-gradient 100%) border-box;
        border: 2px solid transparent;
        width: 100%;
        @media (min-width: $tablet) {
            padding: 0rem !important;
        }
    }
    .modal-title {
        p {
            padding-left: 2rem;
            padding-top: 1rem;
            margin-top: 0.5rem;
        }
    }
    .video-mask {
        width: 100%;
        border-radius: 1rem;
        padding-inline: 1rem;
        padding-bottom: 1rem;
        overflow: hidden;
    }
    .video-js {
        width: 100% !important;
        border-radius: 1rem;
        background-color: #071935;
    }
    .close-background {
        @media (min-width: $tablet) {
            right: 1rem !important;
            top: 1rem !important;
        }
    }
}

.home-page {
    .header-section {
        .o-bg-image-section {
            align-items: flex-end;
            @media (max-width: $mobile) {
                background-image: url('/assets/img/bg-home-mobile.jpg') !important;
                min-height: 850px;
            }
        }
    }
    .basement-gang-section {
        .row > * {
            @media (max-width: $mobile) {
                padding: 0 !important;
            }
        }
    }
    .metaverse-section {
        .o-bg-lottie-section {
            .lottie-container {
                @media (max-width: 1280px) {
                    display: none;
                }
            }
            @media (min-width: $desktop) and (max-width: $desktop-xl) {
                min-height: 950px;
            }
            @media (max-width: $mobile) {
                align-items: flex-start;
                background-image: url('/assets/img/bg-metaverse-mobile.jpg') !important;
            }
        }
    }
    .coming-soon-section {
        .o-bg-image-section {
            min-height: 1000px;
            @media (max-width: $mobile) {
                align-items: flex-start;
                background-image: url('/assets/img/mint-section-mobile-bg.webp') !important;
                min-height: 100vh;
            }
        }
    }

    .o-team {
        .m-box-image-item-border {
            min-height: 15rem !important;
            width: 12.5rem !important;
            background: linear-gradient(to right, #67dbff, #0068de) padding-box,
                linear-gradient(to right, #67dbff, #0068de) border-box;
            border-radius: 1.75rem;
            border: 0.25rem solid transparent;
            margin: 0 auto;
        }

        .m-box-image-item {
            min-height: 15rem !important;
            width: 12rem !important;
            border-radius: 1.75rem;
            margin: 0 auto;
        }
        .a-paragraph {
            margin-bottom: 3rem;
        }
        h2 {
            color: white;
            text-transform: uppercase;
        }
        .h4 {
            color: white;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 0 !important;
            font-size: 1.438rem;
        }
        p {
            color: white;
        }
    }

    .rewards-section {
        .image {
            @media (min-width: $tablet) {
                img {
                    width: 50rem !important;
                    height: auto;
                    max-width: fit-content !important;
                }
            }
        }
    }
}

.waitlist-section {
    .o-bg-image-section {
        min-height: 950px !important;
    }

    .o-waitlist-form {
        .form-wrapper {
            @media (min-width: 1400px) {
                width: 80%;
            }
            @media (max-width: 1400px) {
                grid-template-columns: 1fr 1fr !important;
            }
            @media (max-width: 991px) {
                grid-template-columns: 1fr !important;
            }
        }
    }
}

.o-share-social-modal {
    .a-icon .primary svg {
        fill: none !important;
    }
}

.o-buy-now-modal {
    .o-buy-now-dialog {
        background-image: url('/assets/img/bg-basement-gang-blue.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 2rem;
        @media (max-width: $tablet) {
            padding: 0;
        }
        border-radius: 1.75rem;
    }
    .o-buy-now-supermode {
        background-image: url('/assets/img/bg-basement-gang.jpg') !important;
    }
    .close-button {
        text-align: end;
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
}

.o-waitlist-modal,
.o-buy-now-modal,
.o-share-social-modal {
    h4 {
        margin-bottom: 4rem;
    }
    .modal-content {
        position: unset !important;
    }
    .social-modal-icons {
        img {
            width: 5rem !important;
            height: auto;
        }
    }
    .modal-dialog {
        background-image: url('/assets/img/bg-basement-gang.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 2rem;
        border-radius: 1.75rem;
        @media (min-width: $desktop) {
            padding: 10rem 18rem;
        }
    }
    .waitlist-modal {
        .form-wrapper {
            grid-template-columns: 1fr !important;
        }

        input {
            background: transparent;
            border-style: solid;
            border-color: antiquewhite;
        }
    }

    .modal-mint-content {
        border-radius: 1rem;
        @media (min-width: $tablet) {
            background: linear-gradient(#382f59 0%, #180c3e 100%) padding-box,
                linear-gradient(to right, $secondary-gradient 0%, $primary-gradient 100%) border-box;
            border: 2px solid transparent;
            display: inline-block;
        }
        h5 {
            font-weight: 600 !important;
        }
        .wallet-button {
            background-color: white !important;
            .wrapper {
                display: grid;
                grid-template-columns: 1fr 2fr 1fr;
                > div {
                    justify-self: flex-start;
                }
            }
            p {
                color: $gray-700 !important;
                text-transform: capitalize !important;
                align-self: center;
            }
        }
    }
}

.m-cta-banner {
    .background {
        padding: 5rem 0;
        background-image: url('/assets/img/bg-cta.jpg') !important;
        @media (max-width: $mobile) {
            background-image: url('/assets/img/bg-cta-mobile.jpg') !important;
        }
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
    }
    p {
        @media (max-width: $mobile) {
            text-align: center;
        }
    }
    a {
        width: auto;
        @media (max-width: $mobile) {
            width: 100%;
        }
    }
}
.swal2-modal {
    background-size: cover !important;
    height: 22.5rem;
    .swal2-title {
        color: white;
    }
    .swal2-image {
        margin-top: 4rem;
    }
    .swal2-footer {
        border-top: 0px !important;
        color: white !important;
        margin: inherit !important;
    }
}

.waitlist-input {
    input {
        font-family: $headings-font-family;
        font-weight: 600;
        color: $gray-500;
        padding: 1rem 3rem;
        border-radius: 5rem;
        &:focus {
            color: $gray-500;
        }
        &::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            font-family: $headings-font-family;
            font-weight: 600;
            color: $gray-500;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            font-family: $headings-font-family;
            font-weight: 600;
            color: $gray-500;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            font-family: $headings-font-family;
            font-weight: 600;
            color: $gray-500;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-family: $headings-font-family;
            font-weight: 600;
            color: $gray-500;
        }
        &::-ms-input-placeholder {
            /* Microsoft Edge */
            font-family: $headings-font-family;
            font-weight: 600;
            color: $gray-500;
        }
        &::placeholder {
            /* Most modern browsers support this now. */
            font-family: $headings-font-family;
            font-weight: 600;
            color: $gray-500;
        }
    }
}

.welcome-page {
    .logo-loader {
        img {
            width: 16rem !important;
            height: auto;
            @media (max-width: $tablet) {
                width: calc(10rem + 5%) !important;
            }
        }
    }
    .o-scalio-video-section {
        height: 100vh;
    }
    .btn-enter {
        @media (max-width: $mobile) {
            font-size: calc(0.2rem + 2.3vw);
        }
    }
}

$border: 3px;

%gradientBorderContainer {
    position: relative;
    background-clip: padding-box;
    border: solid $border transparent;
}

%gradientBorderBefore {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: linear-gradient(to right, $secondary-gradient 0%, $primary-gradient 100%);
}

.gradient-card.card {
    box-sizing: border-box;
    color: #fff;
    background-color: #121721;
    @extend %gradientBorderContainer;

    &::before {
        @extend %gradientBorderBefore;
    }
}

.story-page {
    .header-section {
        .o-bg-image-section {
            min-height: 850px;
            margin-top: 8rem;
            @media (max-width: $tablet) {
                min-height: 350px;
                margin-top: 6rem;
            }
        }
    }

    .body-section {
        .o-bg-image-section {
            align-items: flex-start;
        }
    }
    .cards-image {
        border-radius: 1rem;
        .a-image {
            border-radius: 1rem;
        }
    }
    .gradient-card {
        margin: auto;
    }
    .video-card {
        border: 2px solid #273f5e;
        background-color: #121721;
        .content {
            z-index: 2;
        }
    }

    .featured-card {
        flex-direction: row !important;
    }
}

.gang-modal {
    @extend %gradientBorderContainer;
    border-radius: 12px;

    &:before {
        @extend %gradientBorderBefore;
    }

    @media (min-width: $mobile) {
        .close-background {
            margin-top: 1rem;
        }
    }

    .modal-dialog {
        padding-left: 0;
        padding-block: 0;
        border-radius: inherit;

        h4 {
            text-transform: uppercase !important;
        }

        .text-condensed {
            line-height: 1.5rem;
            font-weight: 300;
        }
    }
}

.gang-modal {
    .modal-image {
        border-radius: 0.5rem;
        max-height: 715px;
        width: 100%;
    }
}

.modal-dialog.mobile-navbar {
    background-color: #121721;
    position: absolute;
    top: 0px;
    left: 1rem;
    width: calc(100% - 2rem);

    .modal-header {
        background-color: #000;
        height: 102px;
        border-bottom: 5px solid #121721;

        svg {
            fill: #9ca3af;
        }
    }

    .nav-item > button {
        width: 100%;
        text-align: center;
    }
}

.puzzle-desc {
    p,
    span {
        font-weight: 300;
        font-size: 1rem;
    }
}

.meet-page {
    .neon-logo {
        .lottie-container {
            display: flex;
            justify-content: center;
        }
    }
}

.mint-now-page {
    h6 {
        font-weight: 600;
    }
    .neon-logo {
        top: -5rem;
        left: 0;
        right: 0;
        text-align: center;
        img {
            width: 20rem !important;
            height: auto !important;
        }
    }
    .o-bg-lottie-section {
        background-position: center;
        @media (max-width: 1690px) {
            min-height: 70vh !important;
        }
    }

    .lottie-container {
        @media (max-width: 1280px) {
            display: none;
        }
    }
}
.negative-index {
    z-index: -1;
}

@media (min-width: $mobile) {
    p.d-md-webkitbox {
        display: -webkit-box !important;
    }
}

.modal-board {
    .modal-dialog {
        padding: 0px;
        border-radius: 1.75rem;

        img {
            border-radius: 1.75rem;
        }

        .close-background {
            top: 1rem !important;
            right: 1rem !important;
        }
    }
}

/*------------------------------------*\
    #NFT Detail Page
\*------------------------------------*/

.nfts-detail-page {
    margin-top: 10rem;
    color: white;
    .a-image {
        border-radius: 1rem;
        overflow: hidden;
        height: initial !important;
    }
    .content-info {
        display: flex;
        gap: 1rem;
        @media (max-width: $tablet) {
            flex-direction: column;
        }
    }
    .properties-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        @media (max-width: $desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media (max-width: $tablet) {
            grid-template-columns: 1fr 1fr;
        }
        gap: 1rem;
    }
}

/*------------------------------------*\
    NFTS Filters
\*------------------------------------*/
.sticky-filter-top {
    position: sticky;
    top: 8rem;
    z-index: 1020;
}

.nfts-filter {
    .filter-btn {
        width: 85%;
        background: #191c1f;
        border: 0 !important;
        border-radius: 0.5rem;
        margin: 1.5rem 1rem;
        text-transform: capitalize;
        .content {
            display: grid;
            grid-template-columns: 1rem 1fr;
            gap: 0.5rem;
            p {
                justify-self: baseline;
            }
        }
    }
    overflow: hidden;
    .border-bottom {
        border-top: 1px solid $gray-900 !important;
        border-bottom: 3px solid $gray-900 !important;
    }
}

.nfts-filter-container {
    .form-switch .form-check-input {
        width: 2.5em;
        height: 1.5rem;
    }
    .accordion-item {
        input,
        textarea {
            background: transparent;
            border: 1px solid $gray-700;
            border-radius: 2rem;
            height: 2rem;
            padding: 0.5rem;
            color: white;
            outline: none;
        }
        select {
            background: transparent;
            border: 1px solid $gray-700;
            border-radius: 5rem;
            padding: 0.5rem;
            color: white;
            outline: none;
            background-image: url('/assets/icons/icon-dropdown.png');
            background-position: right 0.75rem center;
            background-size: 12px 8px;
            background-repeat: no-repeat;
        }
    }
}

/*------------------------------------*\
    NFTS Grid
\*------------------------------------*/

.nft-card {
    height: 100%;
    .btn-card {
        text-transform: capitalize;
        // width: 6.25rem;
        height: 1.875rem;
        padding: 0.2rem 1rem;
    }
    .small-font {
        font-size: $small-font-size;
    }
}

.cardToFadeIn {
    animation: fadeIn 3s linear 1 forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*------------------------------------*\
    NFTS Rare Mode
\*------------------------------------*/

.rare-class {
    .body {
        background-image: url('/assets/img/supermode-mode-bg.jpg') !important;
        background-size: cover !important;
        transition: 0.5s all !important;
        height: 100vh;
    }
    .nfts-filter {
        background-color: #0d004a !important;
        transition: 0.5s all;
    }
    .nft-card {
        background: rgb(25 28 31 / 80%);
        border: 1px solid transparent;
    }
    .gallery-page {
        .filter-topbar {
            background: #0d004a;
            border-radius: 0.5rem;
            .shuffle-btn {
                background-color: transparent;
                border: 1px solid white !important;
            }
            .search-box {
                background-color: transparent;
                border: 1px solid white;
                input::-webkit-input-placeholder {
                    color: white;
                }
                input:-ms-input-placeholder {
                    color: white;
                }
                input::placeholder {
                    color: white;
                }
                .search-icon {
                    path {
                        opacity: 1 !important;
                    }
                }
            }
        }
    }
}

/*------------------------------------*\
    #Modal
\*------------------------------------*/
.modal-backdrop.show {
    opacity: 0.8;
}
.modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
    // filter: blur(8rem);
    // backdrop-filter: blur(5px);
    // -webkit-backdrop-filter: blur(5px);
}

.modal-content {
    background-color: transparent;
    border: 0 !important;
}
.modal-body {
    background-color: #0f172a;
    border: 1px solid #94a3b8;
    border-radius: 2rem;
    padding: 2rem;
    overflow: hidden;
    color: white;
}

.gallery-page {
    .banner-title {
        font-family: 'Inter' !important;
        text-transform: none;
    }
    .card-height {
        height: 10rem !important;
    }
    .container-fluid {
        width: 100% !important;
    }
    .m-banner {
        margin-top: 8rem;
        background-image: url('/assets/img/nft-banner-bg.jpg');
        background-position: left;
        background-repeat: no-repeat;
        &.secondary-bg {
            background: linear-gradient(97deg, #4a3cf0 0%, #8d2aed 100%);
        }

        .img-background {
            @media (min-width: $tablet) {
                background-image: url('/assets/img/header-grid-image.png');
                background-position: right bottom;
                background-repeat: no-repeat;
            }
        }
    }
    .filter-topbar {
        background: #000;
        border-radius: 0.5rem;
        .filter-btn {
            background: #191c1f;
            border: 0 !important;
            border-radius: 0.5rem;
            text-transform: capitalize;
            padding: 0.5rem 1rem;
            .content {
                display: grid;
                grid-template-columns: 1rem 1fr;
                gap: 0.5rem;
                p {
                    justify-self: baseline;
                }
            }
        }
        .shuffle-btn {
            background-color: #191c1f;
            border-radius: 0.5rem;
            padding: 0.5rem 0.8rem;
            border: 0 !important;
        }
        .btn-primary {
            font-family: 'Inter' !important;
            border: 0 !important;
            font-weight: 300;
            padding: 0.5rem 1rem !important;
            text-transform: unset;
            border-radius: 0.5rem !important;
            @media (max-width: $mobile) {
                margin-top: 1rem;
            }
        }
        .search-box {
            font-size: 0.875rem;
            padding: 0.5rem 1rem 0.5rem 2rem;
            border: 0;
            background-color: #191c1f;
            width: 30%;
            @media (max-width: $mobile) {
                width: 100%;
            }
            border-radius: 0.5rem;
            transition: 0.2s;
            input {
                font-family: 'Inter' !important;
                letter-spacing: -0.2px;
                font-size: 0.875rem;
                border: none;
                color: white;
                width: 100%;
                background: transparent;
                &:hover {
                    cursor: pointer;
                }
                &:focus {
                    outline: none;
                }
            }
            .search-icon {
                top: 0.5rem;
                left: 0.8rem;
                path {
                    opacity: 0.5;
                }
            }
        }
    }
    .no-nft-content {
        .a-image {
            display: flex;
            justify-content: center;
            height: auto;
            img {
                width: 15rem !important;
                height: auto;
                margin-bottom: 1rem;
            }
        }
    }
}

// Training Gym

.training-gym {
    .hero-section {
        .h2 {
            font-weight: 600;
            @media (min-width: $mobile) {
                width: 18ch !important;
                font-size: 3.75rem;
            }
        }
        p {
            @media (min-width: $tablet) {
                width: 49ch !important;
            }
        }
    }
    .no-nfts-section {
        .image {
            @media (min-width: $tablet) {
                img {
                    width: 50rem !important;
                    height: auto;
                    max-width: fit-content !important;
                }
            }
        }
    }
    .no-nfts-training {
        .image {
            @media (max-width: $tablet) {
                img {
                    max-width: 350px !important;
                }
            }
        }
    }
    .no-train-banner {
        .number {
            img {
                width: 5rem !important;
            }
        }
        .image {
            img {
                width: 30rem !important;
            }
        }
        .rotate-image {
            img {
                height: 22rem !important;
                width: auto !important;
            }
        }
    }
}

// Trainging Modal

.modalBudsTraining {
    .nft-detail {
        p {
            font-weight: 400;
            font-size: 1rem;
        }
        .cat {
            font-weight: 600;
        }
    }
    .modal-dialog {
        background-image: url('/assets/img/bg-basement-gang.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .modal-content {
        padding: 4rem 0;
        @media (min-width: $tablet) {
            padding: 2rem 3rem;
        }
    }
    .buds-card-placeholder {
        margin-bottom: 2rem;
        background: unset !important;
        border: 40px solid transparent;
        border-image: url('/assets/img/buds-border.png') 70 round;
        padding: 0 !important;
        video {
            width: calc(15rem + 6vw) !important;
            height: auto;
            border-radius: 1rem;
        }
    }
    .modal-title {
        font-weight: 600 !important;
    }
    .fw-600 {
        font-weight: 600;
    }
    .divider-left {
        position: relative;
        &::before {
            content: '';
            height: 100%;
            width: 1px;
            background-color: #564e8a;
            position: absolute;
            left: 0;
        }
    }
    .non-trained {
        .buds-card-placeholder {
            background: url('/assets/img/modal-bud-card-placeholder.png') !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            background-position: center !important;
        }
        video {
            width: 100%;
            height: 100%;
            border-radius: 1rem;
        }
    }
    .buds-card-placeholder {
        justify-content: center;
        height: 95% !important;
        small {
            font-size: 0.875rem !important;
        }
    }
    .buds-card-image {
        min-height: calc(271px + (386 - 271) * ((100vw - 320px) / (1440 - 320))) !important;
        min-width: 100% !important;
    }
    .gradient-card.card {
        background-color: #120351;
    }
    h6 {
        font-family: 'TTNorms' !important;
        font-weight: 600 !important;
    }
    .small {
        font-family: 'TTNorms' !important;
        font-weight: 400 !important;
        font-size: 0.75rem !important;
        padding-left: 1.8rem;
        @media (max-width: $tablet) {
            padding-left: 1rem;
        }
        margin-right: 0.6rem;
    }
    .a-image {
        img {
            width: 5rem !important;
            margin-top: 0.5rem;
        }
    }
    .progress-step-bar {
        li {
            list-style: none;
            display: inline-table;
            width: 30.33%;
            position: relative;
            text-align: center;
            &:before {
                content: url('/assets/icons/status-uncheck.png');
                display: block;
            }
            &:after {
                content: '';
                position: absolute;
                width: 74%;
                height: 2px;
                background-color: #564e8a;
                top: 14px;
                left: -41%;
                z-index: 0;
            }
            &:first-child:after {
                content: none;
            }
            &.active {
                &:before {
                    content: url('/assets/icons/status-check.png');
                }
                + li:after {
                    background-color: #fc9700;
                }
            }
        }
    }
    .training-blocked {
        background: rgb(0 0 0 / 40%);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
        backdrop-filter: blur(3px);
        &.border {
            border-color: $secondary-gradient !important;
        }
        &.block-larger {
            width: 100%;
            height: 100%;
        }
        img {
            width: 2rem !important;
        }
    }
}

.mint-error-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
        max-height: 50%;
        width: auto;
        max-width: 100%;
        transform: translateY(10rem);
        margin-top: -10rem;
        @media (max-width: $desktop) {
            transform: translateY(2rem);
            margin-top: -2rem;
        }
    }
}

.account-change-modal {
    .close-button {
        text-align: end;
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
}

.buy-nft-modal {
    .modal-dialog {
        padding: 10rem 3rem !important;
    }
}

.web3modal-modal-lightbox {
    z-index: 1031 !important;
}

.staking-error-modal .modal-dialog {
    max-width: 400px !important;
}

.staking-backdrop {
    position: fixed;
    background-color: #000000cf;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.no-buds-modal {
    h3 {
        width: 17ch;
        @media (max-width: $tablet) {
            width: 13ch;
        }
    }
}
